<template>
  <div class="py-4 px-2 p-md-4">
    <a-tabs v-model:activeKey="activeKey" id="deploy-page-tabs">
      <a-tab-pane key="1" id="deploy-model-tab">
        <template #tab>
          <span>
            Deploy model
          </span>
        </template>
        <active-devices></active-devices>
      </a-tab-pane>
      <a-tab-pane key="2" id="validate-tab">
        <template #tab>
          <span>
            Validate
          </span>
        </template>
        <main-validation></main-validation>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import MainValidation from './MainValidation.vue';
import ActiveDevices from './ActiveDevices.vue';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { showNotification } from '../../../shared/Helpers/showNotification';

export default defineComponent({
  components: {
    MainValidation,
    ActiveDevices,
  },

  setup() {
    onMounted(() =>
      setTimeout(
        showNotification,
        500,
        'Looking for Device(s)',
        'We are looking for active devices in your organization. This will take a few seconds.',
        InfoCircleOutlined,
        '#108ee9'
      )
    );

    onBeforeUnmount(notification.destroy);
    return {
      activeKey: ref('1'),
    };
  },
});
</script>
<style></style>
