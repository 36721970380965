import urlHelper from './urlHelper';

export default function(fileUrl, folderName) {
  const fileName = fileUrl
    ?.split(`/${folderName}/`)
    ?.at(-1)
    ?.split('.json')[0];
  console.log('filenmae after dec', fileName);
  return urlHelper.decodeUrl(fileName);
}
