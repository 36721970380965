<template>
<device-notification-check></device-notification-check>
  <a-modal
    id="deploy-model-schedule-task-model"
    v-model:visible="showSchedule"
    title="Schedule Task"
    ok-text="Schedule"
    :mask-closable="false"
    :ok-button-props="{ id: 'dm-schedule-task-ok-btn' }"
    :cancel-button-props="{
      id: 'dm-schedule-task-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    @ok="scheduleInference"
  >
    <template #closeIcon>
      <close-outlined id="trimming-modal-close-btn" />
    </template>
    <a-spin v-if="isScheduling" />
    <div v-else>
      <a-select
        id="dm-schedule-task-days-select"
        v-model:value="selectedDays"
        class="w-100"
        mode="multiple"
        placeholder="Select day(s)"
        :options="daysOptions"
      />
      <a-time-range-picker
        id="dm-time-range-input"
        v-model:value="startEndTime"
        class="w-100 mt-3"
        format="HH:mm"
        style="width: 400px"
      />
    </div>
  </a-modal>

  <a-table
    id="dep-model-active-devices-table"
    :columns="columns"
    :data-source="activeDevicesList"
    :loading="isLoadingDevices"
    :scroll="{ x: true }"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <a-typography-title :level="5">
        Device(s) Active: &nbsp;
        <a-tag id="record-active-devices-count" color="blue">
          {{ activeDevicesCount }}
        </a-tag>
      </a-typography-title>
    </template>

    <template #emptyText>
      <div
        id="dep-model-no-devices"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2">No Active Device Found</span>
      </div>
    </template>

    <template :id="'dep-model' + title + '-hdcell'" #headerCell="{ title }">
      <span class="text-center">
        {{ title }}
      </span>
    </template>

    <template #bodyCell="{ record, column, index }">
      <div
        v-if="column.dataIndex === 'deviceId'"
        :id="'dep-model-deviceId-' + index"
        class="text-center"
      >
        {{ record.display_name || record.Serial_number }}
      </div>

      <div v-if="column.dataIndex === 'status'" class="text-center">
        <!-- Inference -->
        <a-tag
          v-if="
            record.isInferenceRunning ||
              (record.is_shift_managed && record.inference)
          "
          :id="'dm-status-inference-' + index"
          color="processing"
        >
          Running Inference
        </a-tag>

        <!-- Uploading -->
        <div v-else-if="record?.uploading">
          <a-tag
            :id="'dep-device-' + index + '-uploading-status'"
            color="processing"
          >
            <template #icon>
              <cloud-upload-outlined />
            </template>
            Uploading
          </a-tag>
          <a-tag v-if="record?.uploading?.name" color="success">
            {{ record?.uploading?.name }}
          </a-tag>
          <a-progress
            :id="'dep-device-' + index + '-uploading-progress'"
            type="circle"
            :percent="record?.uploading?.progress"
            :width="40"
          />
        </div>

        <!-- Downloading -->
        <div v-else-if="record?.downloading">
          <a-tag
            :id="'dm-status-downloading-button-' + index"
            color="processing"
          >
            <template #icon>
              <cloud-upload-outlined />
            </template>
            Downloading
          </a-tag>
          <a-tag :id="'dm-status-downloaded-button-' + index" color="success">
            {{ record?.downloading.name }}
          </a-tag>

          <a-progress
            :id="'dm-download-progress-button-' + index"
            type="circle"
            :percent="record?.downloading?.progress"
            :width="40"
          />
        </div>

        <a-tag v-else :id="'dep-model-status-none-' + index">
          None
        </a-tag>
      </div>

      <div
        v-if="column.dataIndex === 'task'"
        :id="'dep-model-task-' + index"
        class="text-center"
      >
        <a-select
          :id="'dep-model-select-device-task-' + index"
          :ref="(el) => (taskInputRefs[record.Serial_number] = el)"
          show-search
          class="w-100"
          :value="record.Task"
          placeholder="Select Task"
          :options="taskOptions"
          :loading="isLoadingTasks"
          :disabled="record?.isInferenceRunning"
          :filter-option="filterOption"
          @change="(val) => handleChangeTask(record, val)"
        />
      </div>

      <div class="text-center" v-if="column.dataIndex === 'action'">
        <a-button
          :id="'dep-model-task-record-button-' + index"
          :disabled="!record.Task"
          block
          type="primary"
          @click="handleInference(record)"
        >
          {{ record?.isInferenceRunning ? 'Stop' : 'Start' }}
          Task
        </a-button>

        <a-button
          :id="'dep-model-task-schedule-button-' + index"
          class="my-1"
          type="primary"
          block
          @click="showScheduleModal(record, 'inference')"
        >
          Schedule Task
        </a-button>

        <a-space
          :id="'dep-model-trace-switch-' + index"
          class="clickable"
          @click="handleTrace(record)"
        >
          <a-switch :checked="record?.recordInference" />
          <span>Trace</span>
        </a-space>
      </div>
    </template>
  </a-table>
</template>
<script>
import {
  CloudUploadOutlined,
  LaptopOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import spaceMixin from 'src/mixins/handleSpace';
import daysOptions from 'src/config/days-config.js';
import columns from 'src/config/columns-config.js';
import { useActiveDevices } from '../RecordData/composables/useActiveDevices';
import DeviceNotificationCheck from 'src/components/user-panel/pages/devices/DeviceNotificationCheck.vue'

export default {
  components: {
    LaptopOutlined,
    CloudUploadOutlined,
    CloseOutlined,
    DeviceNotificationCheck
  },
  mixins: [spaceMixin],
  setup() {
    const {
      activeDevicesList,
      showScheduleModal,
      scheduleInference,
      isScheduling,
      selectedDays,
      startEndTime,
      isLoadingDevices,
      activeDevicesCount,
      updateDeviceSettings,
      handleRecording,
      showSchedule,
      taskInputRefs,
      taskOptions,
      handleChangeTask,
      handleInference,
    } = useActiveDevices();

    function handleTrace(record) {
      const { recordInference } = record;
      updateDeviceSettings({
        ...record,
        key: 'recordInference',
        value: !recordInference,
      });
    }

    return {
      columns,
      handleChangeTask,
      activeDevicesList,
      showScheduleModal,
      scheduleInference,
      isScheduling,
      selectedDays,
      startEndTime,
      showSchedule,
      isLoadingDevices,
      activeDevicesCount,
      taskInputRefs,
      updateDeviceSettings,
      handleRecording,
      daysOptions,
      taskOptions,
      handleTrace,
      handleInference,
    };
  },
};
</script>
<style scoped></style>
